
import { Options, Vue } from 'vue-class-component';
import UpdateCollectionInfo from '../../../components/updateCollectionInfo.vue';

@Options({
    components: {
        UpdateCollectionInfo
    }
})
export default class CollectionInfo extends Vue {

    estimationIdentifier: string = "";
    loaded: boolean = false;
    
    created() {
        this.estimationIdentifier = this.$route.params.identifier.toString();
        setTimeout(() => {
            this.loaded = true;
        }, 300);
    }
}
